<script>
import FilterGenerator from '@/components/general/FilterGenerator'
import SkillCardLevel from '@/components/general/SkillCardLevel'
import ModalSkill from '@/components/skills/ModalSkill'
export default {
  name: 'WorkspaceSkillTab',
  components: {
    ModalSkill,
    SkillCardLevel,
    FilterGenerator
  },
  data () {
    return {
      showSkillModal: false,
      currentMountedFilters: {},
      workspaceId: this.$route.params.id,
      userSkills: []
    }
  },
  watch: {
    // 'currentMountedFilters' () {
    //   this.updateSkills()
    // }
  },
  computed: {
    filterOptions () {
      return {
        actionButton: {
          vif: false,
          color: '#1200D3',
          icon: 'mdi-plus',
          text: this.$t('skills:add.skill'),
          outline: true
        },
        filterFields: [
          { key: 'name_search', type: 'input_field', text: this.$t('global:search') },
          { key: 'skill_level', type: 'select_field', text: this.$t('global:skill.level'), options: this.availableSkills }
        ]
      }
    },
    availableSkills () {
      return this.contentLevels.map(skill => {
        return { id: skill, text: this.$t(`skill.modal.levels:${skill}`) }
      })
    },
    filteredSkills () {
      if (!this.userSkills.length) return []
      return this.userSkills
        .filter(skill => {
          return (
            !skill.deleted &&
            (this.currentMountedFilters.textualDescription ? skill.title.toLowerCase().includes(this.currentMountedFilters.textualDescription.toLowerCase()) : true) &&
            (this.currentMountedFilters.skillsLevel ? skill.level === this.currentMountedFilters.skillsLevel : true)
          )
        })
        .map(skill => ({
          id: skill.id,
          text: skill.title,
          ...skill
        }))
    },
    workspaceData () {
      return this.$store.getters.getWorkspaceInfos[this.workspaceId]
    }
  },
  methods: {
    mountFilter (filter) {
      const mountedFilter = {}
      if (filter.name_search) {
        mountedFilter.textualDescription = filter.name_search
      }
      if (filter.skill_level) {
        mountedFilter.skillsLevel = filter.skill_level
      }
      return mountedFilter
    },
    changedFilter (resultFilter) {
      this.currentMountedFilters = this.mountFilter(resultFilter)
    },
    updateSkills () {
      const userOwner = this.workspaceData.owner.userId
      this.$store.dispatch('attemptGetSkillsByUser', userOwner)
        .then(data => {
          this.userSkills = data
        })
    },
    removeUserSkill (skill) {
      this.userSkills = this.userSkills.filter(userSkill => userSkill.id !== skill.id)
    },
    toggleSkillModal () {
      this.showSkillModal = !this.showSkillModal
      this.updateSkills()
    }
  },
  created () {
    this.updateSkills()
    this.filterOptions.actionButton.vif = this.profileBelongsLoggedUser
  }
}
</script>
<template>
  <div class="workspace-skill--tab center-medium">
    <filter-generator
      :options="filterOptions"
      @change="changedFilter"
      @actionBtnSubmit="toggleSkillModal"
    />
    <modal-skill v-if="showSkillModal" @close="toggleSkillModal" color="indigo"/>
    <div class="workspace-skills-tab">
      <v-container v-if="(!filteredSkills || !filteredSkills.length) && (!currentMountedFilters || objIsEmpty(currentMountedFilters))" class="empty-block">
        <v-img src="/assets/images/empty-box.png" width="100" class="empty-img"/>
        <p class="empty-text">
          {{$t('learning.list.tab:my.skills.empty.text')}}
        </p>
      </v-container>
      <v-container v-else-if="(!filteredSkills || !filteredSkills.length) && currentMountedFilters" class="empty-block">
        <v-img src="/assets/images/empty-search.svg" width="100" class="empty-img"/>
        <p class="empty-text">
          {{$t('workspace.tab.empty:skills.txt1')}}
        </p>
        <p class="empty-text">
          {{$t('workspace.tab.empty:skills.txt2')}}
        </p>
      </v-container>
      <template v-else v-for="(skill, index) in filteredSkills">
        <skill-card-level
          :key="index"
          :skill="skill"
          @removeSkill="removeUserSkill"
        />
      </template>
    </div>
  </div>
</template>
<style lang="scss">
.workspace-skill--tab.center-medium {
  margin-top: 20px;
  .workspace-skills-tab {
    display: flex;
    flex-wrap: wrap;

    .skill-card-level--container {
      max-width: 285px;
      flex: 1 0 21%;
      margin: 20px 36px 0 0;

      &:nth-child(4n) {
        margin-right: 0;
      }
    }

    .skill-not-found {
      width: 100%;
      p {
        font-size: $font-size-large;
      }
    }
  }

  .empty-block {
    .empty-img {
      margin: 0 auto;
      margin-top: 45px;
      margin-bottom: 30px;
    }
    .empty-text {
      font-family: $lato;
      font-size: $font-size-normal;
      margin-top: -20px;
      color: $neutral-medium;
    }
  }

  @media only screen and (max-width: 992px) {
    .workspace-skills-tab {
      .skill-card-level--container {
        max-width: calc(33% - 22px);
        flex: 1 0 33%;

        &:nth-child(4n) {
          margin-right: 36px;
        }

        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .workspace-skills-tab {
      justify-content: center;

      .skill-card-level--container {
        max-width: calc(50% - 58px);
        flex: 1 0 100%;

        &:nth-child(4n) {
          margin-right: 36px;
        }

        &:nth-child(3n) {
          margin-right: 36px;
        }

        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .workspace-skills-tab {
      justify-content: center;

      .skill-card-level--container {
        max-width: 90%;
        flex: 1 0 100%;
        margin-right: 0;
        &:nth-child(4n) {
          margin-right: 0;
        }

        &:nth-child(3n) {
          margin-right: 0;
        }

        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
