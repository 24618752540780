<script>
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'ModalChangeSkillLevel',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    skill: {
      type: Object,
      required
    },
    maxWidth: {
      type: Number,
      default: 600
    }
  },
  data () {
    return {
      selectedLevel: 0
    }
  },
  computed: {
    levelsAvailables () {
      return this.contentLevels.map(level => this.$t(`skill.modal.levels:${level}`))
    },
    workspaceData () {
      return this.$store.getters.getWorkspaceInfos[this.workspaceId]
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    submit () {
      this.$store.dispatch('submitUserSkillLevelChange', { skill: this.skill, newLevel: this.contentLevels[this.selectedLevel] })
        .then(() => {
          this.$emit('onUpdateSkillLevel', this.contentLevels[this.selectedLevel])
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'success',
            title: this.$t('modal.change.skill.level:success')
          })
          const userOwner = this.workspaceData.owner.userId
          this.$store.dispatch('attemptGetSkillsByUser', userOwner)
            .then(data => {
              this.userSkills = data
            })
        })
        .finally(() => {
          this.close()
        })
    }
  },
  created () {
    this.selectedLevel = this.contentLevels.findIndex(level => level === this.skill.level)
  }
}
</script>
<template>
  <v-dialog
    :value="show"
    :key="show"
    persistent
    :max-width="maxWidth"
  >
    <v-card class="modal-change-skill-level--container">
      <h5 class="h7">{{ $t('modal.change.skill.level:title') }}</h5>
      <span class="top-right"><v-btn icon @click="close" color="#F7BF34"><v-icon>mdi-close</v-icon></v-btn></span>
      <div class="modal-change-skill-level--body-wrapper">
        <h2 class="modal-chage-skill-level--skill__title">{{ skill.title }}</h2>
        <v-slider
          v-model="selectedLevel"
          :tick-labels="levelsAvailables"
          :max="levelsAvailables.length - 1"
          step="1"
          ticks="always"
          thumb-size="20"
          :tick-size="6"
        ></v-slider>
      </div>
      <div class="modal-change-skill-level--actions">
        <v-btn
          text
          color="#1200D3"
          @click="close"
          class="text-secondary bold"
        >
          {{ $t('global:cancel') }}
        </v-btn>
        <v-btn
          :dark="true"
          color="#1200D3"
          class="bold"
          @click="submit"
        >
          {{ $t('global:save') }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.modal-change-skill-level--container {
  font-family: $lato;
  padding: 24px;
  text-align: left;
  .modal-change-skill-level--body-wrapper {
    margin-top: 32px;
    .modal-chage-skill-level--skill__title {
      font-size: $font-size-large;
      font-family: $lato;
      font-weight: normal;
    }
  }
  .v-slider__tick {
    border-radius: 50px;
    height: 8px;
    width: 8px;
    background: $neutral-dark;
  }
  .primary.lighten-3.v-slider__track-background {
    background: $neutral-light !important;
  }
  .v-slider__thumb {
    height: 20px;
    width: 20px;
    &:before {
      height: 20px;
      width: 20px;
      top: 0;
      left: 0;
    }
  }
  .v-slider--horizontal .v-slider__tick .v-slider__tick-label {
    top: 14px;
    font-family: $lato;
  }
  .modal-change-skill-level--actions {
    text-align: right;
    margin-top: 40px;
    .v-btn:nth-child(1) {
      margin-right: 8px;
    }
  }
}
</style>
