<script>
import { required } from 'vuelidate/lib/validators'
import LevelBar from '@/components/general/LevelBar'
import ModalChangeSkillLevel from '@/components/general/ModalChangeSkillLevel'
import ConfirmationModal from '@/components/general/ConfirmationModal'
export default {
  name: 'SkillCardLevel',
  components: {
    LevelBar,
    ConfirmationModal,
    ModalChangeSkillLevel
  },
  props: {
    skill: {
      type: Object,
      required
    }
  },
  data () {
    return {
      showSkillRemovalModal: false,
      showModalChangeSkillLevel: false,
      menuOptions: ['edit', 'delete'],
      barOptions: [
        { level: 'interest', color: 'secondary-lightest' },
        { level: 'basic', color: 'secondary-light' },
        { level: 'intermediate', color: 'secondary-medium' },
        { level: 'advanced', color: 'secondary-dark' }
      ]
    }
  },
  methods: {
    executeAction (action) {
      switch (action) {
        case 'edit':
          this.toggleShowModalChangeSkillLevel()
          break

        case 'delete':
          this.toggleShowSkillRemovalModal()
          break
      }
    },
    toggleShowModalChangeSkillLevel () {
      this.showModalChangeSkillLevel = !this.showModalChangeSkillLevel
    },
    toggleShowSkillRemovalModal () {
      this.showSkillRemovalModal = !this.showSkillRemovalModal
    },
    removeSkill () {
      this.$store.dispatch('attemptRemoveUserSkill', this.skill)
      this.toggleShowSkillRemovalModal()
      this.$emit('removeSkill', this.skill)
    },
    onUpdateSkillLevel (newSkillLevel) {
      this.skill.level = newSkillLevel
    }
  },
  computed: {
    capitalizedTitle () {
      return this.skill.title.length > 40
        ? (this.skill.title.charAt(0) + this.skill.title.slice(1, 40)).toLocaleUpperCase() + '...'
        : this.skill.title.toLocaleUpperCase()
    }
  }
}
</script>
<template>
  <div class="skill-card-level--container">
    <v-card>
      <div v-if="!!skill.isOwner" class="skill-card-level--actions">
        <v-menu bottom left absolute transition="slide-x-transition" min-width="200">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small icon color="#1200D3" v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(action, i) in menuOptions"
              :key="i"
              @click="executeAction(action)"
            >
              <v-list-item-title>{{ $t(`global:${action}`) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="skill-card-level--name">
        <v-tooltip top v-if="skill.title.length > 40">
          <template v-slot:activator="{ on, attrs }">
          <v-card-title v-on="on" v-bind="attrs">{{ capitalizedTitle }}</v-card-title>
          </template>
          <span>{{skill.title}}</span>
        </v-tooltip>
        <v-card-title v-else>{{ capitalizedTitle }}</v-card-title>
      </div>
      <div class="skill-card-level--level">
        <level-bar
          :bar-options="barOptions"
          :bar-level="skill.level"
        />
      </div>
    </v-card>
    <modal-change-skill-level
      :show="showModalChangeSkillLevel"
      :skill="skill"
      @close="toggleShowModalChangeSkillLevel"
      @onUpdateSkillLevel="onUpdateSkillLevel"
    ></modal-change-skill-level>
    <confirmation-modal
      :show="showSkillRemovalModal"
      :title="$t('skill.modal.remove:title')"
      :description="$t('skill.modal.remove:description', { skillName: skill.title })"
      :confirmText="$t('skill.modal.remove:confirm')"
      @confirm="removeSkill"
      :cancelText="$t('skill.modal.remove:cancel')"
      @cancel="toggleShowSkillRemovalModal"
    ></confirmation-modal>
  </div>
</template>
<style lang="scss">
.v-list .v-list-item__title {
  text-align: left;
}
.skill-card-level--container {
  .skill-card-level--actions {
    display: flex;
    justify-content: flex-end;
    margin: -9px -9px 0 0 ;
  }
  .v-list .v-list-item__title {
    text-align: left;
  }
  .v-card {
    padding: 20px;
  }
  .skill-card-level--name {
    .v-card__title {
      min-height: 100px;
      justify-content: center;
      word-break:keep-all;
      height: $font-size-normal*1.35; /* Fallback for non-webkit */
      line-height: 1.25;
    }
  }
}
</style>
