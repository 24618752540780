<script>
import SkillsTable from './SkillsTable.vue'
import Autocomplete from '@/components/general/Autocomplete'
export default {
  components: {
    SkillsTable,
    Autocomplete
  },
  name: 'ModalSkill',
  data: () => ({
    selectedSkill: '',
    userSkills: [],
    newSkills: [],
    search: '',
    filteredList: [],
    // workaround: https://github.com/vuetifyjs/vuetify/issues/10765
    autoCompleteKey: Math.random()
  }),
  props: {
    icon: { type: String, default: '' },
    btnText: { type: String, default: '' },
    color: { type: String, default: '' }
  },
  watch: {
    selectedSkill (skill) {
      const userSkillIdx = this.userSkills.findIndex(userSkill => userSkill.id === skill.id)
      if (userSkillIdx !== -1) {
        const skill = this.userSkills[userSkillIdx]
        this.userSkills.splice(userSkillIdx, 1)
        this.userSkills.push({ ...skill, deleted: false })
      } else if (!(this.userSkills.some(userSkill => userSkill.id === skill.id)) &&
        !this.newSkills.some(newSkill => newSkill.id === skill.id && !newSkill.deleted)) {
        this.newSkills.push({ ...skill, level: 'interest', new: true, createdAt: new Date() })
      }
      this.autoCompleteKey = Math.random()
    },
    search () {
      this.debounceEvent(() => {
        this.$store.dispatch('attemptGetAvailableSkills', { filterByName: this.search, limit: 99999 })
        if (this.search) {
          this.filteredList = this.foundSkills.filter(item => this.customSkillFilter(null, this.search, item.title))
        }
      }, 300)
    }
  },
  computed: {
    currentUserSkills () {
      return this.newSkills.concat(this.userSkills)
    },
    foundSkills () {
      // eslint-disable-next-line camelcase
      return this.$store.getters.getAvailableSkills.map(({ title, id }) => ({ title, id }))
    },
    canAddSkill () {
      return this.search && this.search.trim().length > 0 && this.filteredList.every(item => item.title.toLocaleLowerCase() !== this.search.toLocaleLowerCase().trim())
    }
  },
  methods: {
    handleSkillAdd () {
      const title = this.search
      this.$store.dispatch('attemptSaveNewSkill', this.search)
        .then(({ data }) => {
          this.selectedSkill = { id: data.id, title }
        })
    },
    addNewSkill () {
      return `${this.$t('global:add')} "${this.search}"`
    },
    customSkillFilter (_, query, text) {
      return text.toLocaleLowerCase().indexOf(query.toLocaleLowerCase().trim()) > -1
    },
    handleSelectedSkill (input) {
      if (!input) return
      if (this.currentUserSkills.some(userSkill => userSkill.id === input.id && !userSkill.deleted)) {
        this.$store.dispatch('attemptSetFeedbackMsg', {
          type: 'info',
          title: this.$t('skill.modal:toast.already.present')
        })
        return
      }
      this.selectedSkill = input
    },
    closeModal () {
      this.$emit('close')
      this.newSkills = []
    },
    loadUserSkills () {
      this.$store.dispatch('attemptGetMyselfSkills')

      if (!this.$store.getters.getUser || !this.$store.getters.getUser.skills) {
        return
      }
      this.userSkills = this.$store.getters.getUser.skills
        .map(skill => ({
          id: skill.id,
          text: skill.title,
          ...skill
        }))
    }
  },
  created () {
    this.loadUserSkills()
  }
}
</script>
<template>
<v-dialog
      :value="true"
      persistent
      max-width="1200px"
      content-class="modal-skill--container"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="btnText"
          class="btn-show-modal-add-skill"
          :text="!!icon"
          :dark="!!icon"
          :small="!btnText && !!icon"
          outlined
          v-bind="attrs"
          v-on="on"
          > <v-icon v-if="icon">{{icon}}</v-icon>
          <span v-else class="btn-small bold border-primary">{{btnText}}</span>
        </v-btn>
      </template>
      <v-card class="lato">
        <v-card-title class="modal-skill--header">
          <span class="modal-headline bolder">{{ $t('global:skills') }}</span>
          <span class="top-right"><v-btn icon @click="closeModal"><v-icon>mdi-close</v-icon></v-btn></span>
        </v-card-title>
        <v-card-subtitle class="bolder modal-subtext text-left pt-2">{{ $t('skills.modal:label.add.for.recomendation') }}</v-card-subtitle>
        <autocomplete
          :key="autoCompleteKey"
          :items="foundSkills"
          item-text="title"
          :filter="customSkillFilter"
          :item-value="({ id, title }) => ({ id, title })"
          :search-input.sync="search"
          prepend-inner-icon="mdi-magnify"
          @input="handleSelectedSkill"
          outlined
          dense
          :hide-no-data="!search"
          :label="$t('skills.modal:input.search.skills')"
          class="px-6"
          ref="skillsAutocomplete"
        >
        <template v-slot:no-data>
          <span/>
        </template>
          <v-list-item v-if="canAddSkill" @click="handleSkillAdd" slot="append-item">
            <v-list-item-icon class="mr-2"><v-icon class="primary-medium">mdi-plus</v-icon></v-list-item-icon>
            <v-list-item-content v-text="addNewSkill()"></v-list-item-content>
          </v-list-item>
        </autocomplete>
        <v-card-title class="pt-0 mt-n2">
          <span class="modal-headline-sub bolder">{{ $t('skills.modal:your.skills') }}</span>
        </v-card-title>
        <skills-table
          class="mt-n2"
          :items="currentUserSkills"
          @closeModal="closeModal"
          :key="autoCompleteKey"
        />
      </v-card>
    </v-dialog>
</template>

<style lang="scss">
.modal-skill--container {
  .modal-skill--header {
    .modal-headline {
      font-size: 18px;
      font-family: $lato;
    }
    .top-right .v-icon {
      color: $primary-medium;
    }
  }
  .modal-headline-sub {
    font-size: 14px;
    font-family: $lato;
  }
  .close-btn-wrapper .v-btn.v-btn--flat {
    border-radius: 0;
  }
  .v-card .modal-subtext.v-card__subtitle {
    font-size: 16px;
    font-weight: 400;
    color: $neutral-darkest;
  }
  .v-list .v-list-item__title {
    text-align: left;
  }
  .primary-medium.v-icon {
    color: $primary-medium
  }
  @media screen and (max-width: 768px) {
    .modal-skill--header.v-card__title {
      padding-top: 40px;
    }
    .modal-skill--header.v-card__title + .v-card__subtitle {
      margin-top: 0;
      padding: 0 22px 30px;
    }
  }
}
</style>
