<script>
import ConfirmationModal from '@/components/general/ConfirmationModal'
import SelectField from '@/components/general/SelectField'
export default {
  name: 'SkillTable',
  components: {
    ConfirmationModal,
    SelectField
  },
  data () {
    return {
      dirtyForm: false,
      showConfirmationModal: false,
      showExclusionModal: false,
      skillToRemove: null,
      page: 1,
      itemsPerPage: 5,
      sortBy: 'recent',
      sortOptions: [{ text: this.$t('global:sort.recent'), value: 'recent' }, { text: this.$t('global:sort.alphabetic'), value: 'alphabetic' }],
      userSkills: [],
      isSaving: false,
      header: [
        { text: this.$t('skill.modal.header:title'), value: 'title' },
        { text: this.$t('skill.modal.header:level'), value: 'level', tooltip: true },
        { text: this.$t('skill.modal.header:actions'), value: 'action' }
      ],
      skillList: [
        {
          text: this.$t('skill.modal.levels:interest'),
          value: 'interest'
        },
        {
          text: this.$t('skill.modal.levels:basic'),
          value: 'basic'
        },
        {
          text: this.$t('skill.modal.levels:intermediate'),
          value: 'intermediate'
        },
        {
          text: this.$t('skill.modal.levels:advanced'),
          value: 'advanced'
        }
      ]
    }
  },
  props: {
    items: { type: Array, default: () => [] }
  },
  computed: {
    sortedList () {
      const position = (this.page - 1) * 5
      const isSortedByDate = this.sortBy === 'recent'
      return this.orderByField(this.filteredList, isSortedByDate ? 'createdAt' : 'title', isSortedByDate, isSortedByDate).slice(position, position + 5)
    },
    filteredList () {
      return this.userSkills.filter(item => !item.deleted)
    }
  },
  watch: {
    'userSkills.length' () {
      this.dirtyForm = true
    },
    'items.length' () {
      this.userSkills = this.items
      if (this.sortBy === 'recent') {
        this.updatePage(1)
      }
    }
  },
  methods: {
    updatePage (page) {
      this.page = page
    },
    changeMade () {
      this.dirtyForm = true
    },
    removeSkill (skill) {
      skill.deleted = true
      if (!skill.new) {
        const skillIdx = this.userSkills.findIndex(userSkill => userSkill.id === skill.id)
        if (skillIdx !== -1) {
          this.$set(this.userSkills, skillIdx, skill)
        }
        this.changeMade()
      } else {
        this.userSkills = this.userSkills.filter(userSkill => userSkill.id !== skill.id)
      }
    },
    openConfirmationModal () {
      if (this.dirtyForm) {
        this.showConfirmationModal = true
      } else {
        this.emitCloseModal()
      }
    },
    openExclusionModal (skill) {
      this.skillToRemove = skill
      this.showExclusionModal = true
    },
    closeConfirmationModal () {
      this.skillToRemove = null
      this.showConfirmationModal = false
    },
    closeExclusionModal () {
      this.showExclusionModal = false
    },
    emitCloseModal () {
      this.userSkills = this.items
      this.closeConfirmationModal()
      this.$emit('closeModal')
    },
    confirmExclusion () {
      this.removeSkill(this.skillToRemove)
      this.skillToRemove = null
      this.closeExclusionModal()
    },
    cleanupUserSkills (userSkills) {
      return userSkills.filter(skill => !(skill.deleted && skill.new))
    },
    saveSkills () {
      this.isSaving = true
      this.$store.dispatch('attemptSaveUserSkills', this.cleanupUserSkills(this.userSkills))
        .then(() => {
          this.dirtyForm = false
          this.emitCloseModal()
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'info',
            title: this.$t('skill.modal.table:toast.submit.success')
          })
        })
        .catch(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'error',
            title: this.$t('skill.modal.table:toast.submit.error')
          })
        })
        .finally(() => {
          this.$store.dispatch('attemptGetMyselfSkills')
          this.isSaving = false
        })
    },
    setLevel (event, index) {
      this.sortedList[index].level = event
    },
    showPaginationText ({ pageStart, pageStop, itemsLength }) {
      return `${pageStart}-${pageStop} ${this.$t('skill.modal.pagination.preposition')} ${itemsLength}`
    },
    limitSkillNameSize (skillName) {
      return skillName.length < 20 ? skillName : skillName.slice(0, this.isMobile ? 25 : 50) + '...'
    }
  },
  created () {
    this.userSkills = this.items
    this.$nextTick(() => {
      this.dirtyForm = false
    })
  }
}
</script>

<template>
  <v-container class="skill-table--container" fluid>
    <select-field v-if="sortedList.length > 0" class="skills-order-by--button" v-model="sortBy" :items="sortOptions" />
    <v-data-table
      class="skills-table--wrapper"
      :headers="header"
      :items="filteredList"
      :items-per-page="itemsPerPage"
      :hide-default-footer="sortedList.length === 0"
      :page="page"
      @update:page="updatePage"
      disable-sort
      hide-default-header
      :footer-props="{ 'items-per-page-options': [5] }"
    >
      <template v-slot:header="{ props: { headers } }">
        <thead>
          <tr>
            <th v-for="h in headers" :key="h.value" :class="{'text-right': h.value === 'action'}">
              <span class="table-header">{{h.text}} </span>
              <v-tooltip bottom v-if="h.tooltip">
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on" class="pb-1">mdi-alert-circle</v-icon>
              </template>
              <v-container class="text-left">
                <span><b><i>{{$t('skill.modal.levels:interest')}}: </i></b> {{$t('skill.modal.tooltip:interest')}}</span>
                <br>
                <span><b><i>{{$t('skill.modal.levels:basic')}}: </i></b> {{$t('skill.modal.tooltip:basic')}}</span>
                <br>
                <span><b><i>{{$t('skill.modal.levels:intermediate')}}: </i></b> {{$t('skill.modal.tooltip:intermediate')}}</span>
                <br>
                <span><b><i>{{$t('skill.modal.levels:advanced')}}: </i></b> {{$t('skill.modal.tooltip:advanced')}}</span>
              </v-container>
            </v-tooltip>
            </th>
          </tr>
        </thead>
      </template>
      <template
        v-if="items.length > 0"
        v-slot:body
      >
        <tbody>
          <tr
            v-for="(skill, index) in sortedList"
            :key="skill.id"
            class="skill-table--line"
          >
            <td class="text-left overflow-text skill-name-cell">{{ limitSkillNameSize(skill.title) }}</td>
            <td class="text-left"><v-select class="level-select" :items="skillList" :key="skill.id" @click="changeMade"  :value="skill.level" @change="setLevel($event, index)"></v-select></td>
            <td class="text-right"><v-icon @click="openExclusionModal(skill)" class="warning-medium ml-n1">mdi-delete</v-icon></td>
          </tr>
        </tbody>
      </template>
      <template
        v-if="items.length === 0"
        v-slot:no-data
      >
        {{ $t('skills.modal.table:no.skills.found') }}
      </template>
      <template v-slot:[`footer.page-text`]="data">
        {{ showPaginationText(data) }}
      </template>
    </v-data-table>
    <v-container class="d-flex flex-row-reverse pa-2 actions-container--wrapper">
      <v-btn dark @click="saveSkills" :loading="isSaving" class="mx-2 submit-skills btn transform-unset"> {{$t('global:save')}} </v-btn>
      <v-btn outlined @click="openConfirmationModal" class="cancel-changes btn transform-unset"> {{$t('global:cancel')}} </v-btn>
    </v-container>
    <confirmation-modal
      :show="showConfirmationModal"
      :title="$t('skill.modal.confirm:title')"
      :description="$t('skill.modal.confirm:description')"
      :confirmText="$t('skill.modal.confirm:confirm')"
      @confirm="emitCloseModal"
      :cancelText="$t('skill.modal.confirm:cancel')"
      @cancel="closeConfirmationModal"
    ></confirmation-modal>
    <confirmation-modal
      :show="showExclusionModal"
      :title="$t('skill.modal.delete:title')"
      :description="$t('skill.modal.delete:description')"
      :confirmText="$t('skill.modal.delete:confirm')"
      @confirm="confirmExclusion"
      :btnConfirmText="false"
      :cancelText="$t('global:cancel')"
      @cancel="closeExclusionModal"
    ></confirmation-modal>
  </v-container>
</template>
<style lang="scss">
.skill-table--container {
  position: relative;
  .skills-order-by--button {
    margin: 0 15px;
    max-width: 25%;
  }
  @media only screen and (max-width: 768px) {
    .skills-order-by--button {
      max-width: 100%;
    }
  }
}
.theme--dark.v-btn.mx-2.submit-skills:not(.v-btn--flat) {
  background-color: $primary-medium;
}
.table-header {
  color: $neutral-dark;
  font-size: 12px;
}
.v-icon.warning-medium {
  color: $warning-medium;
}
.v-data-footer {
  padding: 25px 0;

  .v-data-footer__icons-before,
  .v-data-footer__icons-after {
    margin-left: 5px;
    border-radius: 4px;
  }
}
.overflow-text {
  max-width: 600px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.skill-table--container {
  padding-top: 0;
}
.skill-table--line {
  padding: 0;
  height: 20px;
}
.skills-table--wrapper {
  max-height: 500px;
  overflow: auto;
  .skill-name-cell {
    max-width: 250px;
  }
}
.actions-container--wrapper {
  position: sticky;
  bottom: 0;
  margin: 0 12px;
  max-width: unset;
  width: 100%;
  background: $neutral-white;
  .submit-skills {
    font-weight: bold;
  }
  .cancel-changes {
    color: $primary-medium;
    font-weight: bold;
  }
}
.level-select {
  width: 200px;
}
@media only screen and (max-width: 1025px) {
  .skill-table--container {
    padding-bottom: 0;
  }
  .skills-table--wrapper {
    max-height: unset;
    overflow: unset;
  }
}
@media only screen and (max-width: 768px) {
  .skills-table--wrapper {
    max-height: unset;
    overflow: unset;
    .skill-name-cell {
      min-width: 230px;
    }
  }
}
</style>
