<script>
import { required } from 'vuelidate/lib/validators'
import SelectField from '@/components/general/SelectField'
export default {
  name: 'FilterGenerator',
  components: { SelectField },
  props: {
    options: {
      type: Object,
      required
    }
  },
  methods: {
    onChange () {
      const mountedFilter = {}
      this.options.filterFields.forEach(filter => {
        mountedFilter[filter.key] = typeof filter.value === 'string' ? filter.value.trim() : filter.value
      })
      this.$emit('change', mountedFilter)
    },
    submitActionClick () {
      this.$emit('actionBtnSubmit')
    },
    itemText (item) {
      if (!item.text) return item
      return item.text.length < 40 ? item.text : item.text.slice(0, 39) + '...'
    }
  }
}
</script>
<template>
  <div class="filter-generator-wrapper">
    <div class="filter-option filter-button">
      <v-btn v-if="options.actionButton.vif" class="btn transform-unset" :outlined="options.actionButton.outline"
        :color="options.actionButton.color" @click="submitActionClick"><v-icon>{{
          options.actionButton.icon
        }}</v-icon>{{ options.actionButton.text }}</v-btn>
    </div>
    <div class="filter-option" v-for="(filter, index) in options.filterFields" :key="index">
      <v-text-field v-if="filter.type === 'input_field'" v-model="filter.value" :placeholder="filter.text" outlined
        dense append-icon="mdi-magnify" hide-details="auto" @input="onChange" clearable></v-text-field>
      <select-field v-if="filter.type === 'select_field'" v-model="filter.value" :multiple="filter.multiple"
        :placeholder="filter.text" :outlined="!filter.outline" hide-details="auto" hideValidation
        :items="filter.options" item-value="id" :item-text="itemText" clearable :firstOnly="filter.firstOnly"
        @input="onChange"></select-field>
    </div>
  </div>
</template>
<style lang="scss">
.filter-generator-wrapper {
  display: flex;
  align-items: flex-start;
  flex-flow: wrap;

  .filter-option {
    max-width: 251px;
    margin-right: 16px;

    &.filter-button {
      max-width: 100%;
      width: 100%;
      text-align: left;
      margin-bottom: 20px;
    }

    .v-input {
      &.v-text-field--outlined {
        background: white;

        .v-label {
          top: 11px;
        }

        >.v-input__control {
          >.v-input__slot {
            min-height: 40px;
          }
        }

        &:not(.v-text-field--single-line) {
          .v-select__selections {
            padding: 4px 0;

            .v-chip {
              margin: 1px 0 -1px !important;
            }
          }
        }
      }

      &.v-select {
        max-width: 420px;
      }
    }

    .v-text-field--enclosed {

      .v-input__append-inner,
      .v-input__prepend-inner {
        margin-top: 8px;
      }
    }

    .v-input.v-text-field:not(.v-text-field--outlined) {
      padding-top: 1px;

      >.v-input__control {
        >.v-input__slot:before {
          bottom: -1px;
        }
      }
    }
  }

  @media only screen and (max-width: 1150px) {
    .filter-option {
      width: calc(33% - 16px);
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    .filter-option.filter-button {
      margin: 0 0 20px;
      width: 100%;
    }

    >.filter-option {
      margin: 0 0 20px;
      width: 100%;
      max-width: unset;

      .validation--wrapper.hide-validation {
        margin: 0;
      }

      .v-input.v-select {
        max-width: unset;
      }

      .v-btn {
        width: 100%;
      }
    }
  }
}
</style>
