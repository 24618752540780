<script>
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'LevelBar',
  props: {
    barOptions: {
      type: Array,
      required
    },
    barLevel: {
      type: String,
      required
    }
  },
  computed: {
    isActiveBar () {
      return this.barOptions.findIndex(bar => this.barLevel === bar.level)
    }
  }
}
</script>
<template>
  <div class="level-bar--container">
    <p class="level-bar--title">{{ $t(`skill.modal.levels:${barLevel}`) }}</p>
    <div class="level-bar--options">
      <template v-for="(levelOption, index) in barOptions">
        <div :key="levelOption.level" :class="[{'active': isActiveBar >= index}, levelOption.color]" class="level-bar--option"></div>
      </template>
    </div>
  </div>
</template>
<style lang="scss">
.level-bar--container {
  display: flex;
  flex-direction: column;
  .level-bar--options {
    display: flex;
    flex-wrap: nowrap;
    .level-bar--option {
      height: 8px;
      flex: 1 1 10px;
      margin-right: 4px;
      background-color: $neutral-light;
      &:nth-child(1) {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
      &:nth-last-child(1) {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
  .level-bar--title {
    @extend .lato;
    margin: 0 0 4px;
    text-align: left;
    color: $neutral-dark;
    font-size: 14px;
  }
  .active {
    &.secondary-lightest {
      background-color: $secondary-lightest;
    }
    &.secondary-light {
      background-color: $secondary-light;
    }
    &.secondary-medium {
      background-color: $secondary-medium;
    }
    &.secondary-dark {
      background-color: $secondary-dark;
    }
  }
}
</style>
